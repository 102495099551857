<template>
	<main class="relative">
		<div v-if="pageText && pageText[locale]" class="w-full z-10 flex absolute">
			<div class="w-1/12 md:w-1/4 lg:w-1/2 xl:w-7/12"></div>
			<div class="bg-color-main-light w-11/12 md:w-3/4 lg:w-1/2 xl:w-5/12 rounded-bl-full">
				<div class="flex flex-row justify-center px-4 sm:px-8 py-3 text-xs">
					<span class="mr-1 hidden md:inline">
						{{ pageText[locale].timetoapply }}
					</span>
					<span class="font-bold mr-4 hidden md:inline">
						{{
							pageText[locale].timeinminutes.replace(
								'%{value}',
								averageMinutesApplication && averageMinutesApplication.toString()
							)
						}}
					</span>
					<span class="mr-1">
						{{ pageText[locale].successfullyhired }}
					</span>
					<span class="font-bold">
						{{
							pageText[locale].candidatesplaced.replace(
								'%{value}',
								marketingValues && marketingValues.candidatesPlaced
							)
						}}
					</span>
				</div>
			</div>
		</div>
		<section class="relative">
			<div v-if="pageText" class="relative container pt-16 sm:pt-20 z-10">
				<div class="w-full flex flex-col justify-between">
					<div class="w-full max-w-xl">
						<h1 class="text-left">{{ pageText[locale].pagetitle }}</h1>
						<h2 class="mb-8 md:mb-16 max-w-lg xl:max-w-xl">
							{{ pageText[locale].subtitle }}
						</h2>
					</div>
					<div class="max-w-2xl shadow-xl rounded-lg w-full">
						<div class="w-full px-8 py-8 sm:py-12 sm:px-16 flex flex-col">
							<h4 class="mb-8">{{ pageText[locale].countryselect }}</h4>
							<div class="w-full flex space-x-6">
								<nuxt-link
									to="https://hokify.at"
									class="inline-block"
									@click="saveCountryPreference('at')"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 600 600"
										class="w-16 rounded-full"
									>
										<rect fill="#f00" width="600" height="600"></rect>
										<rect fill="#fff" y="200" width="600" height="200"></rect>
									</svg>
								</nuxt-link>
								<nuxt-link
									to="https://hokify.de"
									class="inline-block"
									@click="saveCountryPreference('de')"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 3 3"
										class="w-16 rounded-full"
									>
										<rect width="3" height="3" y="0" x="0" fill="#000"></rect>
										<rect width="3" height="2" y="1" x="0" fill="#D00"></rect>
										<rect width="3" height="1" y="2" x="0" fill="#FFCE00"></rect>
									</svg>
								</nuxt-link>
								<nuxt-link
									to="https://hokify.ch"
									class="inline-block"
									@click="saveCountryPreference('ch')"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 320 320"
										preserveAspectRatio="xMidYMid meet"
										xml:lang="de"
										class="w-16 rounded-full"
									>
										<rect fill="#f00" height="320" width="320"></rect>
										<rect fill="#fff" height="60" width="200" x="60" y="130"></rect>
										<rect fill="#fff" height="200" width="60" x="130" y="60"></rect>
									</svg>
								</nuxt-link>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="!$isMobile.any" class="pl-10 absolute w-1/2 top-0 right-0 hidden lg:block">
				<div class="w-full h-0 relative" style="padding-top: 69%">
					<div
						class="absolute inset-0 bg-center bg-no-repeat bg-cover max-h-[500px]"
						:style="`background-image: url(${landingTop})`"
					/>
				</div>
			</div>
		</section>
		<section class="container pt-8 pb-12 sm:pt-12">
			<div class="mt-3 text-center">
				<h4 class="block sm:hidden">Bekannt aus:</h4>
				<div class="inline-block text-center">
					<nuxt-link
						v-for="logo in marketingLogos"
						:key="logo.imgSource"
						:to="logo.imgLink"
						class="mt-4 sm:mt-3 mx-3 lg:mx-2 xl:mx-3 relative inline-block"
					>
						<img
							:src="getAssetSrc(logo.imgSource)"
							:alt="logo.imgAlt"
							class="inline-block h-6 align-middle"
							style="max-width: 110px"
						/>
					</nuxt-link>
				</div>
			</div>
		</section>
		<CallToAction v-if="pageText" styling="user-banner" to="mailto:info@hokify.com">
			<template #headline>
				<b>{{ pageText[locale].ctatitle }}</b
				><br />
				{{ pageText[locale].ctasubtitle }}
			</template>
			<template #button> {{ pageText[locale].ctabutton }} </template>
		</CallToAction>
		<section v-if="pageText" class="container max-w-5xl text-center py-12 sm:py-20">
			<div class="w-full mb-10 sm:mb-12">
				<h2 class="mb-6">
					{{
						pageText[locale].companiestitle.replace(
							'%{value}',
							marketingValues && marketingValues.publishingUsers
						)
					}}
				</h2>
				<p class="text-xl mb-0">
					{{
						pageText[locale].companiessubtitle.replace(
							'%{value}',
							marketingValues && marketingValues.publishingUsers
						)
					}}
				</p>
			</div>

			<div class="w-full flex flex-wrap flex-row mx-2">
				<div
					v-for="(logo, index) in customerLogos"
					:key="index"
					class="w-1/2 sm:w-1/4 md:w-1/6 h-16 px-4 relative"
				>
					<img
						:src="getAssetSrc(logo.imgSource)"
						:alt="logo.imgAlt"
						class="inline-block center-in-parent w-auto object-contain max-w-[100px] max-h-[48px]"
					/>
				</div>
			</div>
		</section>
		<section class="w-full h-0 relative ar-21:9">
			<div
				class="absolute inset-0 bg-cover bg-center bg-no-repeat"
				:style="`background-image: url(${hokifyTeam})`"
			/>
		</section>
		<section v-if="pageText" class="container max-w-5xl text-center py-12 sm:py-16">
			<div class="w-full md:pr-8">
				<h2 class="mb-6">{{ pageText[locale].contacttitle }}</h2>
				<p class="text-xl mb-0">
					{{ pageText[locale].contactsubtitle }}
				</p>
			</div>
		</section>
		<section class="w-full h-0 relative" style="padding-top: 51.77%">
			<div
				class="absolute inset-0 bg-cover bg-center bg-no-repeat"
				:style="`background-image: url(${explanationVisualB2B})`"
			/>
		</section>
		<section v-if="pageText" class="container max-w-5xl text-center pt-10 pb-16 sm:pb-20">
			<h4 class="mb-1">{{ pageText[locale].ctatitle }}</h4>
			<p class="mb-8">
				{{ pageText[locale].ctasubtitle }}
			</p>
			<HokButton to="mailto:info@hokify.com" color="blue">
				{{ pageText[locale].ctabutton }}
			</HokButton>
		</section>
	</main>
</template>

<script lang="ts">
import {
	getCustomerLogos,
	getMarketingLogos
} from '@hokify/shared-components-nuxt3/lib/helpers/logos';
import CallToAction from '@hokify/shared-components-nuxt3/lib/components/CallToAction.vue';
import HokButton from '@hokify/shared-components-nuxt3/lib/components/HokButton.vue';
import { saveCountryPreference } from '../helpers/preferences';
import pageText from '~/data/text.json';
import landingTopUrl from '~/assets/img/landing-top.jpg';
import hokifyTeamUrl from '~/assets/img/hokify-team.jpg';
import explanationVisualB2BUrl from '~/assets/img/explanation-visual-b2b.jpg';

// eslint-disable-next-line no-undef
export default defineNuxtComponent({
	name: 'StartPage',
	components: {
		CallToAction,
		HokButton
	},
	async asyncData({ req }) {
		const config = useRuntimeConfig();
		const { baseURL } = config.public;
		const { data: marketingValues } = await useFetch(
			`${baseURL}/website-api/general/marketing-values`
		);
		let locale = 'de';

		if (process.client) {
			const newLocale = window.navigator?.language?.split('-')[0];
			if (newLocale !== 'de') {
				locale = 'en';
			}
		} else if (req?.headers['accept-language']) {
			const newLocale = req.headers['accept-language']
				.split(',')[0]
				.toLocaleLowerCase()
				.substring(0, 2);
			if (newLocale !== 'de') {
				locale = 'en';
			}
		}

		return {
			locale,
			marketingValues
		};
	},
	data() {
		const landingTop = landingTopUrl;
		const hokifyTeam = hokifyTeamUrl;
		const explanationVisualB2B = explanationVisualB2BUrl;
		const marketingValues = undefined as
			| undefined
			| {
					publishingUsers: string;
					monthlyUsers: string;
					candidatesPlaced: string;
					averageSecondsApplication: number;
					expirationDate: number;
			  };

		const locale = 'de';

		return {
			landingTop,
			hokifyTeam,
			explanationVisualB2B,
			locale,
			pageText,
			marketingValues,
			customerLogos: getCustomerLogos('de'),
			marketingLogos: getMarketingLogos('de').concat(getMarketingLogos('at'))
		};
	},
	methods: {
		saveCountryPreference,
		// works - but performance might be bad?
		// how can we access @shared-components without accessing node_modules?
		getAssetSrc(name: string) {
			const path = `../../node_modules/@hokify/shared-components-nuxt3/assets/svgs/logos/${name}`;
			const modules = import.meta.glob(
				'../../node_modules/@hokify/shared-components-nuxt3/assets/svgs/logos/**/*',
				{ eager: true }
			) as Record<string, { default: string }>;

			if (modules[path]) {
				return modules[path].default;
			}
			return '';
		}
	},
	computed: {
		averageMinutesApplication(): number | null {
			return (this.marketingValues && this.marketingValues.averageSecondsApplication / 60) || null;
		}
	}
});
</script>

<style scoped lang="scss">
@import '~/assets/styles/center_in_parent.scss';
</style>
